import React from "react";

const SystemMessage: React.FC<{ message: any }> = ({ message }) => {
    return (
      <div className="react-chatbot-kit-chat-bot-message-container">
        <div className="react-chatbot-kit-custom-avatar">
          System Auto-Generated
        </div>
        <div className="react-chatbot-kit-chat-bot-message">
          {message.message}
        </div>
      </div>
    );
  };

export default SystemMessage;