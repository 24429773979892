import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorIcon from "@mui/icons-material/Error";
import CircularProgress from "@mui/material/CircularProgress";
import styled from "@emotion/styled";
import { useSubmissionsContext } from "../../../context/submissions";
import { useEffect } from "react";

// get the category name
const getCategory = (status: string) => {
  switch (status) {
    case "ready_for_review":
      return "Submitted"
    case "incomplete":
      return "Missing";
    case "declined":
      return "Declined";
    default:
      return "Unknown status";
  }
};

// get the category button color based on the submission status
interface StyledButtonProps {
  status: string;
}

const StyledButton = styled(Button)<StyledButtonProps>((prop) => {
  let backgroundColor;
  let color;
  switch (prop.status) {
    case "ready_for_review":
      backgroundColor = "#D3F5CB";
      color = "#175B06";
      break;
    case "incomplete":
      backgroundColor = "#FFE2AB";
      color = "#C73C00";
      break;
    case "declined":
      backgroundColor = "#F4BFBF";
      color = "#9F0000";
      break;
    default:
      backgroundColor = "inherit";
      color = "inherit";
  }
  return {
    backgroundColor,
    color,
    width: "100%",
    border: `1px solid ${color}`,
    "&:hover": {
      backgroundColor,
    },
  };
});

// get the icon style for notes based on the submission status
interface StyledIconProps {
  status: string;
}

const StyledIcon = styled.div<StyledIconProps>((prop) => {
  let backgroundColor;
  switch (prop.status) {
    case "ready_for_review":
      backgroundColor = "#9BF185";
      break;
    case "incomplete":
      backgroundColor = "#FFC148";
      break;
    case "declined":
      backgroundColor = "#FFC148";
      break;
    default:
      backgroundColor = "inherit";
  }
  return {
    display: "inline-block",
    backgroundColor,
    borderRadius: "50%",
    height: "24px",
    width: "24px",
    marginRight: "8px",
    verticalAlign: "bottom",
  };
});

// render the icon in notes based on the submission status
const renderIcon = (status: string) => {
  return (
    <StyledIcon status={status}>
      {status === "ready_for_review" ? (
        <CheckIcon
          style={{
            color: "#434756",
            height: "100%",
            width: "100%",
          }}
        />
      ) : (
        <ErrorIcon
          style={{
            color: "#434756",
            height: "100%",
            width: "100%",
          }}
        />
      )}
    </StyledIcon>
  );
};

const getNotes = (status: string) => {
  switch (status) {
    case "ready_for_review":
      return "Has all items needed to submit application";
    case "incomplete":
      return "Submission has missing items";
    case "declined":
      return "Outside guidelines";
    default:
      return "Unknown status";
  }
};

// sort the table rows based on the submission status
const getStatusOrder = (status: string) => {
  switch (status) {
    case "ready_for_review":
      return 0;
    case "incomplete":
      return 1;
    case "declined":
      return 2;
    default:
      return 3;
  }
};

export default function Submissions() {
  const { submissions, fetchSubmissions, isLoadingSubmissions } =
    useSubmissionsContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchSubmissions();
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [fetchSubmissions]);

  if (isLoadingSubmissions) {
    // Loading state while data is being fetched
    return (
      <Box display="flex" flexDirection="column" height="100%">
        <Typography variant="h5" fontWeight="bold" mb={2}>
          Submission List
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          flex="1"
        >
          <CircularProgress sx={{ mb: 2, color: "#CEBBF3" }} />
          <Typography variant="h6" fontWeight="normal" mb={2}>
            Loading Submissions...
          </Typography>
        </Box>
      </Box>
    );
  }

  if (submissions.length === 0) {
    return (
      <Box>
        <Typography variant="h5" fontWeight="bold" mb={2}>
          Submission List
        </Typography>
        <Typography variant="h6" mb={2}>
          There are no submissions yet. Please upload a submission to start
        </Typography>
      </Box>
    );
  }

  const sortedSubmissions = submissions.sort((a, b) => {
    const statusComparison =
      getStatusOrder(a.applicationStatus) - getStatusOrder(b.applicationStatus);
    return statusComparison === 0
      ? new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      : statusComparison;
  });

  return (
    <Box>
      <Typography variant="h5" fontWeight="bold" mb={2}>
        Submission List
      </Typography>
      <TableContainer
        component={Paper}
        sx={{
          backgroundColor: "transparent",
          boxShadow: "none",
          backgroundImage: "none",
        }}
      >
        <Table
          sx={{
            minWidth: 650,
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h6" color="white" fontWeight="normal">
                  Status
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" color="white" fontWeight="normal">
                  Account
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" color="white" fontWeight="normal">
                  Notes
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedSubmissions.map((submission) => (
              <TableRow
                key={submission.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <StyledButton
                    variant="contained"
                    href={`/submissions/${submission.id}`}
                    status={submission.applicationStatus}
                  >
                    {getCategory(submission.applicationStatus)}
                  </StyledButton>
                </TableCell>
                <TableCell>{submission.accountName}</TableCell>
                <TableCell>
                  <StyledIcon status={submission.applicationStatus}>
                    {renderIcon(submission.applicationStatus)}
                  </StyledIcon>
                  {getNotes(submission.applicationStatus)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
