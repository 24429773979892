import {
  createContext,
  PropsWithChildren,
  useContext,
  useCallback,
  useState,
} from "react";
import { Submission } from "../../components/types";
import { getSubmissions, getSubmission } from "../../api/submissions";

type SubmissionsContextProps = {
  submissions: Submission[];
  fetchSubmissions: () => Promise<void>;
  isLoadingSubmissions: boolean;

  submission: Submission | undefined;
  fetchSubmission: (submissionId: string) => Promise<void>;
  isLoadingSubmission: boolean;
};

const SubmissionsContext = createContext<SubmissionsContextProps>(
  {} as SubmissionsContextProps
);

function useSubmission() {
  const [submissions, setSubmissions] = useState<Submission[]>([]);
  const [isLoadingSubmissions, setLoadingSubmissions] = useState<boolean>(true);

  const [submission, setSubmission] = useState<Submission>();
  const [isLoadingSubmission, setLoadingSubmission] = useState<boolean>(true);

  const fetchSubmissions = useCallback(async () => {
    setLoadingSubmissions(true);
    const response = await getSubmissions();
    setSubmissions(response.data);
    setLoadingSubmissions(false);
  }, []);

  const fetchSubmission = useCallback(async (submissionId: string) => {
    setLoadingSubmission(true);
    const response = await getSubmission(submissionId);
    setSubmission(response.data);
    setLoadingSubmission(false);
  }, []);

  return {
    submissions,
    fetchSubmissions,
    isLoadingSubmissions,
    submission,
    fetchSubmission,
    isLoadingSubmission,
  };
}

export function SubmissionsProvider({ children }: PropsWithChildren<{}>) {
  const {
    submissions,
    fetchSubmissions,
    isLoadingSubmissions,
    submission,
    fetchSubmission,
    isLoadingSubmission,
  } = useSubmission();

  return (
    <SubmissionsContext.Provider
      value={{
        submissions,
        fetchSubmissions,
        isLoadingSubmissions,
        submission,
        fetchSubmission,
        isLoadingSubmission,
      }}
    >
      {children}
    </SubmissionsContext.Provider>
  );
}

export function useSubmissionsContext() {
  return useContext(SubmissionsContext);
}
