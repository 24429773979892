import axiosInstance from "./axiosClient";
import { API_URL } from "../constants";
import { Submission } from "../components/types";

export const getSubmissions = async (): Promise<{ data: Submission[] }> => {
  const url = `${API_URL}/v1/submissions`;
  try {
    const response = await axiosInstance.get(url);
    return {
      data: mockSubmissions(response.data),
    };
  } catch (error) {
    console.error("Error getting submissions", error);
    return {
      data: [],
    };
  }
};

export const getSubmission = async (
  submissionId: string
): Promise<{ data: Submission | undefined }> => {
  const url = `${API_URL}/v1/submissions/${submissionId}`;
  try {
    const response = await axiosInstance.get(url);
    return {
      // data: response.data.data
      data: mockSubmission(response.data.data),
    };
  } catch (error) {
    console.error("Error getting a submission", error);
    return {
      data: undefined,
    };
  }
};

export const postSubmissionChat = async (
  query: string,
  submissionId: string
): Promise<{ data: string | undefined; status: number }> => {
  const url = `${API_URL}/v1/submissions/${submissionId}/chat`;
  try {
    const response = await axiosInstance.post(
      url,
      { query },
      {
        responseType: "json",
      }
    );
    return {
      data: response.data.data,
      status: response.status,
    };
  } catch (error) {
    console.error("Error getting a submission", error);
    return {
      data: undefined,
      status: 500, // Default to 500 if no response status is available
    };
  }
};

function mockSubmissions(data: { data: Submission[] }) {
  return data.data.map((submission) => submission);
}

function mockSubmission(submission: Submission) {
  return {
    ...submission,
  };
}
