import { BrowserRouter as Router } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import Providers from './providers'
import Routes from './routes'

function App() {
  return (
    <Router>
      <Providers>
        <CssBaseline />
        <Routes />
      </Providers>
    </Router>
  )
}

export default App
